export enum MIME_TYPE {
  PDF = 'application/pdf',
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  DOCM = 'application/vnd.ms-word.document.macroenabled.12',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  XLSM = 'application/vnd.ms-excel.sheet.macroenabled.12',
  CSV = 'text/csv',
  PPT = 'application/vnd.ms-powerpoint',
  PPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  PPTM = 'application/vnd.ms-powerpoint.presentation.macroenabled.12',
  JPG = 'image/jpeg',
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  TXT = 'text/plain',
}
