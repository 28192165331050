export const splitTextWithLinks = (
  text: string
): { text: string; isLink: boolean }[] => {
  const urlRegex = /https:\/\/[-_.!~*'()a-zA-Z0-9;:?@&=+$,%#\/]+/g;
  const parts: { text: string; isLink: boolean }[] = [];
  let lastIndex = 0;
  text.replace(urlRegex, (url, index) => {
    if (index > lastIndex) {
      parts.push({ text: text.slice(lastIndex, index), isLink: false });
    }
    parts.push({ text: url, isLink: true });
    lastIndex = index + url.length;
    return url;
  });
  if (lastIndex < text.length) {
    parts.push({ text: text.slice(lastIndex), isLink: false });
  }
  return parts;
};
